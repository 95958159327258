var $ = require('jquery');
var imagesLoaded = require('imagesloaded');
var TweenLite = require('gsap');
var scrollTo = require('../../node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin.js');

var Parsley = require('parsleyjs');
var Hammer = require('hammerjs');

window.app = {
	W: $(window).width(),
	H: $(window).height(),

	scrollTop: $(window).scrollTop(),
	isMobileNavOpen: false,
	isHome: $('.page-template-page-home').length > 0,

	sliderInstance: {},

	init: function() {

		var _this = this;

		_this.setupEvents();
		_this.preload([]);
	},

	setupEvents: function() {
		var _this = this,
			$giodo = $('#giodo');

		$('form').on('click', '.button', function(event) {
			event.preventDefault();
			$(this).submit();
		});

		$('.btn-giodo-toggle').on('click', function(event) {
			event.preventDefault();
			
			var $rowGiodo = $('.form-row-giodo')

			if ($rowGiodo.hasClass('open')) {
				$rowGiodo.removeClass('open')
			} else {
				$rowGiodo.addClass('open')
			}

			// $('.form-row-giodo').toggleClass('toggled');
		});

		$giodo.on('change', function(event) {
			event.preventDefault();
			
			if ( $(this).is(':checked') ) {
				$giodo.parent().removeClass('giodo-error')
			} else {
				$giodo.parent().addClass('giodo-error')
			}
		});

		$('form').on('submit', function(event) {
			event.preventDefault();

			var $this = $(this),
				action = $(this).attr('action'),
				data = $(this).serialize(),
				formId = $this.attr('id'),
				isGiodoChecked = $giodo.is(':checked');

			if (isGiodoChecked) {
				$giodo.parent().removeClass('giodo-error')

			    $.ajax({
			      url : action,
			      type : 'POST',
			      cache : false,
			      data : data,
			      beforeSend: function() {
					$this.find('.button').addClass('hidden');
			      }, 
			      success : function(res) {

			        res = JSON.parse(res)

			        if (res.status == true && formId == 'request') {
			        	$this.find('.error').addClass('hidden');
			        	$this.find('.success').removeClass('hidden');
			        	$this.find('.button').addClass('hidden');
			        	$giodo.parent().addClass('hidden');
			        }

			        if (res.status == false && formId == 'request') {
			        	$this.find('.error').removeClass('hidden');
			        	$this.find('.success').addClass('hidden');
			        	$this.find('.button').removeClass('hidden');
			        	$giodo.parent().removeClass('hidden');
			        }
			      }
			    })
			} else {
				$giodo.parent().addClass('giodo-error')
			}
		});

		$('.slider-nav').on('click', 'a', function(event) {
			event.preventDefault();
			var elementIndex = $(this).parent().index();

			_this.sliderInstance.move(elementIndex);
		});

		$('.header-nav-trigger a').click(function(event) {
			event.preventDefault();
			_this.toggleMobileNav();
		});

		$('.page-content-mask').click(function(event) {
			event.preventDefault();
			_this.toggleMobileNav();
		});

		$(window).on('resize', function(event) {
			event.preventDefault();
			_this.onResize();
		});

		$(window).on('scroll', function(event) {
			_this.onScroll();
		});
	},

	onResize: function() {
		app.W = $(window).width()
		app.H = $(window).height()
	},

	onScroll: function() {
		app.scrollTop = $(window).scrollTop()

		if (app.scrollTop > 180 && app.W > 1023) {
			$('.page-phone').addClass('visible');
		} else {
			$('.page-phone').removeClass('visible');
		}
	},

	scrollToTop: function() {
		if ($(window).scrollTop() > 0) {
			this.scrollTo({
				pos: 0, 
				speed: 0.4
			});
		}
	},

	toggleMobileNav: function() {
		var $header = $('header'),
			$nav = $('.header-nav-trigger'),
			$pageContent = $('.page-content');

		if ($header.hasClass('active')) {
			$header.removeClass('active');
			// $pageContent.removeClass('mask');
			$header.removeClass('active');
			$nav.removeClass('active');
		} else {
			$header.addClass('active');
			// $pageContent.addClass('mask');
			$nav.addClass('active');
		}

	},

	scrollTo: function(params) {

		if (params.pos == undefined) {params.pos = 0};
		if (params.speed == undefined) {params.speed = 1};

		TweenLite.to( window, params.speed, {
			scrollTo: {
				y: params.pos
			},
			ease: Cubic.EaseIn, 
			onComplete: function(){
				if (params.after) {
					params.after();
				}
			}
		});
	},

	showHideMobileNav: function(param) {
		var $header = $('header'),
			$nav = $('.header-mobileNav-trigger'),
			param = param || {};
			// $pageContent = $('.page-content');

		if (app.isMobileNavOpen || param.hide == true) {
			$header.removeClass('active');
			$nav.removeClass('active');
			app.isMobileNavOpen = false
		} else {
			$header.addClass('active');
			$nav.addClass('active');
			app.isMobileNavOpen = true
		}
	},

	preload: function(images) {

		var $body = $('body'),
			_this = this;

		if (images) {
			$.each(images, function(index, val) {
				$body.find('.footer').append('<img class="preloading" src=" ' + val +' ">');
			});
		}

		imagesLoaded( 'body', { background: true }, function() {
			$body.find('.preloading').each(function(index, el) {
				$(this).remove();
			});

			setTimeout(function() {
				$body.removeClass('loading');
			}, 400);

			_this.sliderInstance = new _this.slider( $('.slider') );
			_this.sliderInstance.init();
		});
	},

	slider: function($slider) {
		var _this = this,
			sliderIndex = 0,
			sliderMax = $slider.find('.slider-holder .slider-box').length,
			pxToMove = $slider.find('.slider-holder-outer').width(),
			isMoved = false,
			interval,
			previousActive;

		function setupEvents() {
			
			var mc = new Hammer($slider.get(0));

			mc.on("swipeleft", function(ev) {
				moveRight();
			});

			mc.on("swiperight", function(ev) {
				moveLeft();
			});

			$slider.on( "swipeleft",  function(event){
				moveLeft();
			});

			$slider.on( "swiperight",  function(event){
				moveRight();
			});

			$slider.on('click', '.counter-move-left', function(event) {
				event.preventDefault();
				moveLeft();
			});

			$slider.on('click', '.counter-move-right', function(event) {
				event.preventDefault();
				moveRight();
			});

			$slider.on('parentResized', function(event) {
				event.preventDefault();
				pxToMove = $slider.find('.slider-holder-outer').width();

				setSliderBoxWidth();
				move(0);
			});

			$(window).on('resize', function(event) {
				$slider.trigger('parentResized');
			});
		}

		function setSliderBoxWidth() {
			var parentWidth = $slider.find('.slider-holder-outer').width();

			$.each($slider.find('.slider-box'), function(index, val) {
				 $(val).css('width', parentWidth + "px");
			});
		}

		function move(thisIndex) {
			$slider.find('.slider-holder').each(function(index, el) {
				var px;

				if (!isNaN(thisIndex)) {

					sliderIndex = thisIndex;

					$('.slider-count .counter').html(sliderIndex+1);

					$('body').find('.slider-nav li').removeClass('active');
					$('body').find('.slider-nav li:nth-child('+(sliderIndex+1)+')').addClass('active');

					previousActive = $('body').find('.slider-holder li.active');

					$('body').find('.slider-holder li').removeClass('fadeOut');
					previousActive.removeClass('active').addClass('fadeOut');
					$('body').find('.slider-holder li:nth-child('+(sliderIndex+1)+')').addClass('active');
				};
			});
			
			clearSliderInterval();
			startSliderInterval();
		}

		function moveLeft() {
			if (sliderIndex > 0) {
				sliderIndex--;
				move(sliderIndex);
			} else if (sliderIndex < 1) {
				sliderIndex = sliderMax - 1;
				move(sliderIndex)
			}
		}

		function moveRight() {
			if (sliderIndex < sliderMax - 1) {
				sliderIndex++;
				move(sliderIndex);
			} else if (sliderIndex == sliderMax - 1) {
				sliderIndex = 0;
				move(0);
			}
		}

		function undelegateEvents()  {
			$slider.unbind('parentResized');
		}

		function startSliderInterval() {
			interval = setInterval(function() {
				moveRight();
			}, 3000);
		}

		function clearSliderInterval() {
			clearInterval(interval);
		}

		function init() {
			setupEvents();
			setSliderBoxWidth();
			startSliderInterval();

			setTimeout(function() {
				$slider.find('.slider-holder .slider-box').first().addClass('active');
			}, 50);
		}

		return {
			init: init,
			undelegateEvents: undelegateEvents,
			move: move,
			moveRight: moveRight
		}
	}
};

$(document).ready(function() {
	window.app.init();
});